import React from "react";
import "twin.macro";
import { P, H, Button } from "components/typography";
import { hoverableUnderline } from "styles/utilities";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Content, Column } from "./containers";
import ZipForm from "components/composites/BrowseZipForm";

import { useQuery } from "react-query";
import { fetchContentData } from "api/v2/contents";
import Spinner from "components/spinner";

import logo from "components/dwellsocial_logo_regular_reverse.svg";

import { useAuth } from "components/AuthProvider";

const Hero: React.FC = () => {
  const auth = useAuth();
  const loggedIn = auth.loggedIn;

  const { data: heroImage, isLoading } = useQuery(
    ["content", "hero-image"],
    fetchContentData
  );
  const { data: heroCaption, isLoading: captionIsLoading } = useQuery(
    ["content", "hero-caption"],
    fetchContentData
  );

  if (isLoading || captionIsLoading) {
    return (
      <div tw="flex w-full justify-center p-6">
        <Spinner disabled={false} />
      </div>
    );
  }

  return (
    <section
      tw="flex flex-row max-h-42r lg:max-h-50r"
      style={{ height: "80vh", minHeight: "680px" }}
    >
      <Column
        tw="flex bg-gray-700 bg-cover bg-center flex-col justify-between"
        style={{
          backgroundImage: `
          radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%), 
          linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 40%), 
          url('${heroImage}') `
        }}
      >
        <img src={logo} alt="DwellSocial" tw="pt-7 w-32 z-50" />
        {heroCaption && (
          <P.Medium tw="pb-5 text-white font-bold z-50">{heroCaption}</P.Medium>
        )}
      </Column>
      <Column
        tw="-ml-full lg:ml-0 flex flex-col lg:bg-none! lg:bg-transparent!"
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0) 80%)`
        }}
      >
        <section tw="flex justify-end py-6 pt-8 ">
          <Link to={loggedIn ? "/dashboard" : "/session"} component={FancyLink}>
            {loggedIn ? "Dashboard" : "Sign in"}
          </Link>
        </section>
        <Content tw="flex flex-col justify-center flex-grow px-0 lg:pl-1/4 lg:pr-0">
          <i
            className="fas fa-home"
            tw="text-3xl pb-3 text-white lg:text-text"
          ></i>
          <H.One tw="pb-4 text-white lg:text-text">
            Bringing iconic Chicago restaurants to your community
          </H.One>
          <P.Medium tw="pb-6 text-white lg:text-text">
            Enter your zip code to see which restaurants are coming to you!
          </P.Medium>
          <ZipForm />
        </Content>
      </Column>
    </section>
  );
};

const FancyLink: React.FC = ({ children, ...props }) => (
  <LinkComponent as="a" {...props}>
    {children}
  </LinkComponent>
);
const LinkComponent = styled(Button.Tertiary)(() => hoverableUnderline);

export default Hero;
